/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_CreateSubspaceDialog_wrapper {
    .mx_Dialog {
        display: flex;
        flex-direction: column;
    }
}

.mx_CreateSubspaceDialog {
    width: 480px;
    color: $primary-content;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: 0;

    .mx_CreateSubspaceDialog_content {
        flex-grow: 1;

        .mx_CreateSubspaceDialog_betaNotice {
            padding: 12px 16px;
            border-radius: 8px;
            background-color: $header-panel-bg-color;

            .mx_BetaCard_betaPill {
                margin-right: 8px;
                vertical-align: middle;
            }
        }

        .mx_JoinRuleDropdown + p {
            color: $muted-fg-color;
            font-size: $font-12px;
        }
    }

    .mx_CreateSubspaceDialog_footer {
        display: flex;
        margin-top: 20px;

        .mx_CreateSubspaceDialog_footer_prompt {
            flex-grow: 1;
            font-size: $font-12px;
            line-height: $font-15px;
            color: $secondary-content;

            > * {
                vertical-align: middle;
            }
        }

        .mx_AccessibleButton {
            display: inline-block;
            align-self: center;
        }

        .mx_AccessibleButton_kind_primary {
            margin-left: 16px;
            padding: 8px 36px;
        }
    }
}
