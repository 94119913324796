/*
Copyright 2024 New Vector Ltd.
Copyright 2023 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_Flex {
    display: var(--mx-flex-display, unset);
    flex-direction: var(--mx-flex-direction, unset);
    align-items: var(--mx-flex-align, unset);
    justify-content: var(--mx-flex-justify, unset);
    gap: var(--mx-flex-gap, unset);
    flex-wrap: var(--mx-flex-wrap, unset);
}
