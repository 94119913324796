/*
Copyright 2024 New Vector Ltd.
Copyright 2015, 2016 OpenMarket Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

$button-size: 32px;
$icon-size: 22px;
$button-gap: 24px;

:root {
    --image-view-panel-height: 68px;
}

.mx_ImageView {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.mx_ImageView_image_wrapper {
    pointer-events: initial;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
}

.mx_ImageView_image {
    flex-shrink: 0;

    &.mx_ImageView_image_animating {
        transition: transform 200ms ease 0s;
    }

    &.mx_ImageView_image_animatingLoading {
        transition: transform 300ms ease 0s;
    }
}

.mx_ImageView_panel {
    width: 100%;
    height: var(--image-view-panel-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation-name: mx_ImageView_panel_keyframes;
    animation-duration: 300ms;
}

.mx_ImageView_info_wrapper {
    pointer-events: initial;
    padding-left: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $lightbox-fg-color;
    flex-grow: 1;
    flex-basis: 0;
}

.mx_ImageView_info {
    padding-left: 12px;
    display: flex;
    flex-direction: column;
}

.mx_ImageView_info_sender {
    font-weight: bold;
}

.mx_ImageView_title {
    color: $lightbox-fg-color;
    font-size: $font-12px;
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
}

.mx_ImageView_toolbar {
    padding-right: 16px;
    pointer-events: initial;
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
    justify-content: flex-end;
    gap: calc($button-gap - ($button-size - $icon-size));
}

.mx_ImageView_button {
    padding: calc(($button-size - $icon-size) / 2);
    display: block;

    &::before {
        content: "";
        height: $icon-size;
        width: $icon-size;
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        display: block;
        background-color: $icon-button-color;
    }
}

.mx_ImageView_button_rotateCW::before {
    mask-image: url("@res/img/image-view/rotate-cw.svg");
}

.mx_ImageView_button_rotateCCW::before {
    mask-image: url("@res/img/image-view/rotate-ccw.svg");
}

.mx_ImageView_button_zoomOut::before {
    mask-image: url("@res/img/image-view/zoom-out.svg");
}

.mx_ImageView_button_zoomIn::before {
    mask-image: url("@res/img/image-view/zoom-in.svg");
}

.mx_ImageView_button_download::before {
    mask-image: url("@vector-im/compound-design-tokens/icons/download.svg");
}

.mx_ImageView_button_more::before {
    mask-image: url("@vector-im/compound-design-tokens/icons/overflow-horizontal.svg");
    mask-size: 28px;
}

.mx_ImageView_button_close {
    padding: calc($button-size - $button-size);
    border-radius: 100%;
    background: #21262c; /* same on all themes */
    &::before {
        width: $button-size;
        height: $button-size;
        mask-image: url("@vector-im/compound-design-tokens/icons/close.svg");
        mask-size: 24px;
    }
}

@media (prefers-reduced-motion) {
    .mx_ImageView_image_animating {
        transition: none !important;
    }

    .mx_ImageView_image_animatingLoading {
        transition: none !important;
    }
}
