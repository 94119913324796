/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_VoiceRecordComposerTile_stop {
    /* 28px plus a 2px border makes this a 32px square (as intended) */
    width: 28px;
    height: 28px;
    border: 2px solid $voice-record-stop-border-color;
    border-radius: 32px;
    margin-right: 2px; /* between us and the waveform component */
    position: relative;

    &::after {
        content: "";
        width: 14px;
        height: 14px;
        position: absolute;
        top: 7px;
        left: 7px;
        border-radius: 2px;
        background-color: $voice-record-stop-symbol-color;
    }
}

.mx_VoiceRecordComposerTile_delete {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-right: 2px; /* distance from left edge of waveform container (container has some margin too) */
    background-color: $voice-record-icon-color;
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-image: url("@vector-im/compound-design-tokens/icons/delete.svg");
}

.mx_VoiceRecordComposerTile_uploadingState {
    margin-right: 10px;
    color: $secondary-content;
}

.mx_VoiceRecordComposerTile_failedState {
    margin-right: 21px;

    .mx_VoiceRecordComposerTile_uploadState_badge {
        display: inline-block;
        margin-right: 4px;
        vertical-align: middle;
    }
}

.mx_MessageComposer_row .mx_VoiceMessagePrimaryContainer {
    /* Note: remaining class properties are in the PlayerContainer CSS. */

    /* fixed height to reduce layout jumps with the play button appearing */
    /* https://github.com/vector-im/element-web/issues/18431 */
    height: 32px;

    margin: 6px; /* force the composer area to put a gutter around us */
    margin-right: 6px; /* isolate from stop/send button */

    position: relative; /* important for the live circle */

    &.mx_VoiceRecordComposerTile_recording {
        /* We are putting the circle in this padding, so we need +10px from the regular */
        /* padding on the left side. */
        padding-left: 22px;

        &::before {
            animation: recording-pulse 2s infinite;

            content: "";
            background-color: $voice-record-live-circle-color;
            width: 10px;
            height: 10px;
            position: absolute;
            left: 12px; /* 12px from the left edge for container padding */
            top: 17px; /* vertically center (middle align with clock) */
            border-radius: 10px;
        }
    }
}

.mx_MessageComposer_wysiwyg .mx_VoiceMessagePrimaryContainer {
    &.mx_VoiceRecordComposerTile_recording {
        &::before {
            top: 15px; /* vertically center (middle align with clock) */
        }
    }
}

/* The keyframes are slightly weird here to help make a ramping/punch effect */
/* for the recording dot. We start and end at 100% opacity to help make the */
/* dot feel a bit like a real lamp that is blinking: the animation ends up */
/* spending a lot of its time showing a steady state without a fade effect. */
/* This lamp effect extends into why the 0% opacity keyframe is not in the */
/* midpoint: lamps take longer to turn off than they do to turn on, and the */
/* extra frames give it a bit of a realistic punch for when the animation is */
/* ramping back up to 100% opacity. */

/* Target animation timings: steady for 1.5s, fade out for 0.3s, fade in for 0.2s */
/* (intended to be used in a loop for 2s animation speed) */
@keyframes recording-pulse {
    0% {
        opacity: 1;
    }
    35% {
        opacity: 0;
    }
    65% {
        opacity: 1;
    }
}
