/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_UploadConfirmDialog_fileIcon {
    margin-right: 5px;
}

.mx_UploadConfirmDialog_previewOuter {
    text-align: center;
}

.mx_UploadConfirmDialog_previewInner {
    display: inline-block;
    text-align: left;
}

.mx_UploadConfirmDialog_imagePreview {
    max-height: 300px;
    max-width: 100%;
    border-radius: 4px;
    border: 1px solid $dialog-close-fg-color;
}
