/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_NonUrgentToastContainer {
    position: absolute;
    bottom: 30px;
    left: 28px;
    z-index: 101; /* same level as other toasts */

    .mx_NonUrgentToastContainer_toast {
        padding: 10px 12px;
        border-radius: 8px;
        width: 320px;
        font-size: $font-13px;
        margin-top: 8px;

        /* We don't use variables on the colours because we want it to be the same */
        /* in all themes. */
        background-color: #17191c;
        color: #fff;
    }
}
