/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_BeaconStatus {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    box-sizing: border-box;
    padding: $spacing-8;

    color: var(--color);
    font-size: $font-12px;
}

.mx_BeaconStatus_Loading,
.mx_BeaconStatus_Stopped {
    --color: $tertiary-content;
}

.mx_BeaconStatus_Active,
.mx_BeaconStatus_Error {
    --color: $primary-content;
}

.mx_BeaconStatus_icon {
    height: 32px;
    width: 32px;

    flex: 0 0 32px;
    margin-right: $spacing-8;
}

.mx_BeaconStatus_description {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    line-height: $font-14px;

    padding-right: $spacing-8;

    white-space: nowrap;
    overflow: hidden;

    .mx_BeaconStatus_description_status {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.mx_BeaconStatus_expiryTime {
    color: $secondary-content;
}

.mx_BeaconStatus_label {
    margin-bottom: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
}
