/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_AuthHeaderLogo {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mx_AuthHeaderLogo img {
    width: 60%;
}

@media only screen and (max-width: 480px) {
    .mx_AuthHeaderLogo {
        display: none;
    }
}
