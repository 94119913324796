/*
Copyright 2024 New Vector Ltd.
Copyright 2019 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_MessageEditHistoryDialog {
    display: flex;
    flex-direction: column;
    max-height: 60vh;
}

.mx_MessageEditHistoryDialog_scrollPanel {
    flex: 1 1 auto;
}

.mx_MessageEditHistoryDialog_error {
    color: $alert;
    text-align: center;
}

.mx_MessageEditHistoryDialog_edits {
    list-style-type: none;
    font: var(--cpd-font-body-md-regular);
    padding: 0;
    color: $primary-content;

    span.mx_EditHistoryMessage_deletion,
    span.mx_EditHistoryMessage_insertion {
        padding: 0px 2px;
    }

    .mx_EditHistoryMessage_deletion {
        color: rgb(255, 76, 85);
        background-color: rgba(255, 76, 85, 0.1);
        text-decoration: line-through;
    }

    .mx_EditHistoryMessage_insertion {
        color: rgb(26, 169, 123);
        background-color: rgba(26, 169, 123, 0.1);
        text-decoration: underline;
    }

    /* Emulate mx_EventTile[data-layout="group"] */
    .mx_EventTile {
        padding-top: 0;

        .mx_MessageTimestamp {
            position: absolute;
            left: 0;
            text-align: center;
        }

        .mx_EventTile_line {
            padding-top: var(--EventTile_group_line-spacing-block-start);
            padding-bottom: var(--EventTile_group_line-spacing-block-end);
            padding-left: var(--EventTile_group_line-spacing-inline-start);
            line-height: var(--EventTile_group_line-line-height);

            .mx_EventTile_content {
                margin-right: 0px;
            }
        }
        &::before {
            content: "";
            position: absolute;
            top: -1px;
            bottom: -1px;
            left: -10px;
            right: -10px;
            z-index: -1;
            border-radius: 4px;
        }

        &:hover {
            &::before {
                background: $eventbubble-bg-hover;
            }
        }
    }

    .mx_MessageActionBar .mx_AccessibleButton {
        display: flex;
        align-items: center;

        padding-inline-start: $spacing-8;
        padding-inline-end: $spacing-8;

        font-size: $font-15px;
    }
}
