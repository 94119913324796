.mx_RoomGeneralContextMenu_iconStar::before {
    mask-image: url("@vector-im/compound-design-tokens/icons/favourite-solid.svg");
}

.mx_RoomGeneralContextMenu_iconArrowDown::before {
    mask-image: url("@res/img/element-icons/roomlist/low-priority.svg");
}

.mx_RoomGeneralContextMenu_iconMarkAsRead::before {
    mask-image: url("@res/img/element-icons/roomlist/mark-as-read.svg");
}

.mx_RoomGeneralContextMenu_iconMarkAsUnread::before {
    mask-image: url("@res/img/element-icons/roomlist/mark-as-unread.svg");
}

.mx_RoomGeneralContextMenu_iconNotificationsDefault::before {
    mask-image: url("@res/img/element-icons/notifications.svg");
}

.mx_RoomGeneralContextMenu_iconNotificationsAllMessages::before {
    mask-image: url("@res/img/element-icons/roomlist/notifications-default.svg");
}

.mx_RoomGeneralContextMenu_iconNotificationsMentionsKeywords::before {
    mask-image: url("@res/img/element-icons/roomlist/notifications-dm.svg");
}

.mx_RoomGeneralContextMenu_iconNotificationsNone::before {
    mask-image: url("@res/img/element-icons/roomlist/notifications-off.svg");
}

.mx_RoomGeneralContextMenu_iconPeople::before {
    mask-image: url("@vector-im/compound-design-tokens/icons/user-profile-solid.svg");
}

.mx_RoomGeneralContextMenu_iconFiles::before {
    mask-image: url("@vector-im/compound-design-tokens/icons/files.svg");
}

.mx_RoomGeneralContextMenu_iconPins::before {
    mask-image: url("@res/img/element-icons/room/pin-upright.svg");
}

.mx_RoomGeneralContextMenu_iconWidgets::before {
    mask-image: url("@vector-im/compound-design-tokens/icons/extensions-solid.svg");
}

.mx_RoomGeneralContextMenu_iconSettings::before {
    mask-image: url("@vector-im/compound-design-tokens/icons/settings-solid.svg");
}

.mx_RoomGeneralContextMenu_iconExport::before {
    mask-image: url("@vector-im/compound-design-tokens/icons/export-archive.svg");
}

.mx_RoomGeneralContextMenu_iconDeveloperTools::before {
    mask-image: url("@res/img/element-icons/settings/flask.svg");
}

.mx_RoomGeneralContextMenu_iconCopyLink::before {
    mask-image: url("@vector-im/compound-design-tokens/icons/link.svg");
}

.mx_RoomGeneralContextMenu_iconInvite::before {
    mask-image: url("@res/img/element-icons/room/invite.svg");
}

.mx_RoomGeneralContextMenu_iconSignOut::before {
    mask-image: url("@vector-im/compound-design-tokens/icons/leave.svg");
}
