/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_LeaveSpaceDialog_wrapper {
    .mx_Dialog {
        display: flex;
        flex-direction: column;
        padding: 24px 32px;

        .mx_LeaveSpaceDialog {
            width: 440px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            height: 520px;

            .mx_Dialog_content {
                flex-grow: 1;
                margin: 0;
                overflow-y: auto;

                .mx_LeaveSpaceDialog_section_warning {
                    position: relative;
                    border-radius: 8px;
                    margin: 12px 0 0;
                    padding: 12px 8px 12px 42px;
                    background-color: $header-panel-bg-color;

                    font-size: $font-12px;
                    line-height: $font-15px;
                    color: $secondary-content;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 10px;
                        top: calc(50% - 8px); /* vertical centering */
                        height: 16px;
                        width: 16px;
                        background-color: $secondary-content;
                        mask-repeat: no-repeat;
                        mask-size: contain;
                        mask-image: url("@vector-im/compound-design-tokens/icons/info-solid.svg");
                        mask-position: center;
                    }
                }

                > p {
                    color: $primary-content;
                }
            }
        }
    }
}
