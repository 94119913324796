/*
 * Copyright 2025 New Vector Ltd.
 *
 * SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
 * Please see LICENSE files in the repository root for full details.
 */

.mx_RoomListPanel {
    background-color: var(--cpd-color-bg-canvas-default);
    height: 100%;
    border-right: 1px solid var(--cpd-color-bg-subtle-primary);
}
