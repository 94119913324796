/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_PlayPauseButton {
    position: relative;
    width: 32px;
    height: 32px;
    min-width: 32px; /* for when the button is used in a flexbox */
    min-height: 32px; /* for when the button is used in a flexbox */
    border-radius: 32px;
    background-color: $system;

    &::before {
        content: "";
        position: absolute; /* sizing varies by icon */
        background-color: $secondary-content;
        mask-repeat: no-repeat;
        mask-size: contain;
        top: 6px; /* center */
        left: 6px; /* center */
        width: 20px;
        height: 20px;
    }

    &.mx_PlayPauseButton_disabled::before {
        opacity: 0.5;
    }

    &.mx_PlayPauseButton_play::before {
        mask-image: url("@vector-im/compound-design-tokens/icons/play-solid.svg");
    }

    &.mx_PlayPauseButton_pause::before {
        mask-image: url("@vector-im/compound-design-tokens/icons/pause-solid.svg");
    }
}
