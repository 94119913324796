/*
 * Copyright 2024 New Vector Ltd.
 *
 * SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
 * Please see LICENSE files in the repository root for full details.
 */

.mx_SettingsHeader {
    display: flex;
    align-items: center;
    gap: var(--cpd-space-2x);
    /* Override margin from common.pcss */
    margin: 0;

    > span {
        font: var(--cpd-font-body-sm-medium);
        color: var(--cpd-color-text-action-accent);
    }
}
