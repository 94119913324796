/*
Copyright 2024 New Vector Ltd.
Copyright 2023 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_SettingsBanner {
    background: $system;
    border-radius: 8px;
    padding: 12px 16px;
    gap: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .mx_SettingsBanner_content {
        margin: 0;
    }

    .mx_AccessibleButton {
        white-space: nowrap;
    }
}
