.mx_RoomNotificationContextMenu_iconBell::before {
    mask-image: url("@res/img/element-icons/notifications.svg");
}
.mx_RoomNotificationContextMenu_iconBellDot::before {
    mask-image: url("@res/img/element-icons/roomlist/notifications-default.svg");
}
.mx_RoomNotificationContextMenu_iconBellMentions::before {
    mask-image: url("@res/img/element-icons/roomlist/notifications-dm.svg");
}
.mx_RoomNotificationContextMenu_iconBellCrossed::before {
    mask-image: url("@res/img/element-icons/roomlist/notifications-off.svg");
}
