/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_EventTileBubble.mx_MJitsiWidgetEvent {
    &::before {
        background-color: $header-panel-text-primary-color; /* XXX: Variable abuse */
        mask-image: url("@res/img/element-icons/call/video-call.svg");
    }
}
