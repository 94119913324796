/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_FeedbackDialog {
    hr {
        margin: 24px 0;
        border-color: $input-border-color;
    }

    .mx_Dialog_content {
        margin-bottom: 24px;

        > h2 {
            margin-bottom: 32px;
        }
    }

    .mx_FeedbackDialog_section {
        position: relative;
        padding: 12px 52px 12px 68px;
        border-radius: 8px;
        border: 1px solid $quinary-content;

        & + .mx_FeedbackDialog_section {
            margin-top: 16px;
        }

        > h3 {
            margin-top: 0;
            margin-bottom: 8px;
            font-weight: var(--cpd-font-weight-semibold);
            font-size: $font-18px;
            line-height: $font-22px;
        }

        > p {
            color: $secondary-content;
            font-size: $font-15px;
            line-height: $font-24px;
            margin: 8px 0 0;
        }

        .mx_FeedbackDialog_section_microcopy {
            font-size: $font-12px;
            line-height: $font-15px;
        }

        a,
        .mx_AccessibleButton_kind_link {
            text-decoration: underline;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: 40px;
            height: 40px;
            left: 16px;
            top: 12px;
        }

        &::before {
            background-color: $icon-button-color;
            border-radius: 8px;
        }

        &::after {
            background: $avatar-initial-color; /* TODO */
            mask-position: center;
            mask-size: 24px;
            mask-repeat: no-repeat;
        }
    }

    .mx_FeedbackDialog_reportBug {
        &::after {
            mask-image: url("@res/img/feather-customised/bug.svg");
        }
    }

    .mx_FeedbackDialog_rateApp {
        .mx_StyledRadioButton {
            display: inline-flex;
            font-size: 20px;
            transition:
                font-size 1s,
                border 0.5s;
            border-radius: 50%;
            border: 2px solid transparent;
            margin-top: 12px;
            margin-bottom: 24px;
            vertical-align: top;
            cursor: pointer;

            input[type="radio"] + div {
                display: none;
            }

            .mx_StyledRadioButton_content {
                background: $icon-button-color;
                width: 40px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                border-radius: 20px;
                margin: 5px;
            }

            .mx_StyledRadioButton_spacer {
                display: none;
            }

            & + .mx_StyledRadioButton {
                margin-left: 16px;
            }
        }

        .mx_StyledRadioButton_checked {
            font-size: 24px;
            border-color: var(--cpd-color-bg-action-primary-rest);
        }

        &::after {
            mask-image: url("@res/img/element-icons/feedback.svg");
        }
    }
}
