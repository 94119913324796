/*
Copyright 2024 New Vector Ltd.
Copyright 2021 Šimon Brandner <simon.bra.ag@gmail.com>
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_KeyboardShortcut {
    kbd {
        padding: 5px;
        border-radius: 4px;
        background-color: $background;
        min-width: 20px;
        text-align: center;
        display: inline-block;
        border: 1px solid $kbd-border-color;
        box-shadow: 0 2px $kbd-border-color;
        text-transform: capitalize;
    }
}
