/*
Copyright 2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_E2EIconView {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mx_E2EIconView_warning {
    color: var(--cpd-color-icon-critical-primary);
}

.mx_E2EIconView_verified {
    color: var(--cpd-color-icon-success-primary);
}
