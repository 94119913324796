/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_AnalyticsToast {
    .mx_AccessibleButton_kind_danger_outline {
        background-color: $accent;
        color: #ffffff;
        border: 1px solid $accent;
        font-weight: var(--cpd-font-weight-semibold);
    }

    .mx_AccessibleButton_kind_primary {
        background-color: $accent;
        color: #ffffff;
        border: 1px solid $accent;
        font-weight: var(--cpd-font-weight-semibold);
    }
}
