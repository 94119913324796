/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.
Copyright 2019 New Vector Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_CompleteSecurityBody {
    width: 600px;
    color: $authpage-primary-color;
    background-color: $background;
    border-radius: 4px;
    padding: 20px;
    box-sizing: border-box;

    h2 {
        font-size: $font-24px;
        font-weight: var(--cpd-font-weight-semibold);
        margin-top: 0;
    }

    h3 {
        font: var(--cpd-font-body-md-semibold);
    }

    a:link,
    a:hover,
    a:visited {
        color: $accent;
        text-decoration: none;
    }
}
