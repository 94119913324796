/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/
.mx_TextWithTooltip_target {
    display: inline;
}

.mx_TextWithTooltip_tooltip {
    display: none;
}
