/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_MediaBody.mx_AudioPlayer_container {
    padding: 16px 12px 12px 12px;

    .mx_AudioPlayer_primaryContainer {
        display: flex;

        .mx_PlayPauseButton {
            margin-right: 8px;
        }

        .mx_AudioPlayer_mediaInfo {
            flex: 1;
            overflow: hidden; /* makes the ellipsis on the file name work */

            & > * {
                display: block;
            }

            .mx_AudioPlayer_mediaName {
                color: $primary-content;
                font-size: $font-15px;
                line-height: $font-15px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                padding-bottom: 4px; /* mimics the line-height differences in the Figma */
            }

            .mx_AudioPlayer_byline {
                font-size: $font-12px;
                line-height: $font-12px;
            }
        }
    }

    .mx_AudioPlayer_seek {
        display: flex;
        align-items: center;

        .mx_SeekBar {
            flex: 1;
        }

        .mx_Clock {
            min-width: $font-42px; /* for flexbox */
            padding-left: $spacing-4; /* isolate from seek bar */
            text-align: justify;
            white-space: nowrap;
        }
    }
}
