/*
Copyright 2024 New Vector Ltd.
Copyright 2023 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_PollHistory_content {
    height: 600px;
    width: 100%;

    display: flex;
    flex-direction: column;
}

.mx_PollHistory_header.mx_Heading_h2 {
    margin-bottom: $spacing-16;
}
