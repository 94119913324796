/*
Copyright 2024 New Vector Ltd.
Copyright 2023 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_Box--flex {
    flex: var(--mx-box-flex, unset);
}

.mx_Box--shrink {
    flex-shrink: var(--mx-box-shrink, unset);
}

.mx_Box--grow {
    flex-grow: var(--mx-box-grow, unset);
}
