/*
 * Copyright 2025 New Vector Ltd.
 *
 * SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
 * Please see LICENSE files in the repository root for full details.
 */

.mx_RoomList {
    height: 100%;

    .mx_RoomList_List {
        /* Avoid when on hover, the background color to be on top of the right border */
        padding-right: 1px;
    }
}
