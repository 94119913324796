/*
Copyright 2024 New Vector Ltd.
Copyright 2016 Aviral Dasgupta

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_ChangelogDialog_content {
    max-height: 300px;
    overflow: auto;
}

.mx_ChangelogDialog_li {
    padding: 0.2em;
}
