/*
Copyright 2024 New Vector Ltd.
Copyright 2021 Šimon Brandner <simon.bra.ag@gmail.com>

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_DeviceContextMenu {
    max-width: 252px;

    .mx_DeviceContextMenu_device_icon {
        display: none;
    }

    .mx_IconizedContextMenu_label {
        padding-left: 0 !important;
    }
}
