/*
Copyright 2020-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_LegacyCallContextMenu_item {
    width: 205px;
    height: 40px;
    padding-left: 16px;
    line-height: 40px;
    vertical-align: center;
}
