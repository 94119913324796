/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_UntrustedDeviceDialog {
    .mx_Dialog_title {
        display: flex;
        align-items: center;

        .mx_E2EIcon {
            margin-left: 0;
        }
    }

    .mx_Dialog_buttons {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
    }
}
