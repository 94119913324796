/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_DeviceExpandDetailsButton {
    height: 32px;
    width: 32px;
    background: transparent;

    border-radius: 4px;
    color: $icon-button-color;

    --icon-transform: rotate(-90deg);

    &:hover {
        background: $quinary-content;
    }
}

.mx_DeviceExpandDetailsButton.mx_DeviceExpandDetailsButton_expanded {
    --icon-transform: rotate(0deg);

    background: $system;

    &:hover {
        background: $quinary-content;
    }
}

.mx_DeviceExpandDetailsButton_icon {
    height: 24px;
    width: 24px;

    transition: all 0.3s;
    transform: var(--icon-transform);
    transform-origin: center;
}
