.mx_CompatibilityPage {
    width: 100%;
    height: 100%;
    background-color: #e55;
}

.mx_CompatibilityPage_box {
    position: absolute;
    inset: 0;
    margin: auto;
    width: 500px;
    height: 300px;
    border: 1px solid;
    padding: 10px;
    background-color: #fcc;
}
