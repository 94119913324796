/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

/* -------------------------------------------------------------------------------- */
/* DEV NOTE: This stylesheet covers dialogs listed by the compound, including */
/* over multiple React components. The actual inner contents of the dialog should */
/* be in their respective stylesheets. */
/* -------------------------------------------------------------------------------- */

/* Override legacy/default styles for dialogs */
.mx_Dialog_wrapper.mx_CompoundDialog .mx_Dialog {
    padding: 0; /* we'll manage it ourselves */
    color: $primary-content;
}

.mx_CompoundDialog {
    .mx_Dialog {
        display: flex;
        flex-direction: column;
    }

    .mx_CompoundDialog_header {
        padding: 32px 32px 16px 32px;

        h1 {
            display: inline-block;
            font-weight: var(--cpd-font-weight-semibold);
            font-size: $font-24px;
            margin: 0; /* managed by header class */
        }
    }

    .mx_CompoundDialog_cancelButton {
        @mixin customisedCancelButton;
        /* Align with corner radius of dialog */
        position: absolute;
        top: var(--cpd-space-4x);
        right: var(--cpd-space-4x);
    }

    .mx_CompoundDialog_form {
        display: flex;
        flex-direction: column;
        min-height: 0;
        flex: 1;
    }

    .mx_CompoundDialog_content {
        overflow: auto;
        padding: 8px 32px;
        flex: 1;
    }

    .mx_CompoundDialog_footer {
        padding: 20px 32px;
        text-align: right;

        .mx_AccessibleButton {
            margin-left: 24px;
        }
    }
}

.mx_ScrollableBaseDialog {
    display: flex;
    flex-direction: column;

    width: 544px; /* fixed */
    height: 516px; /* fixed */
    max-width: 100%;
    min-height: 0;
    max-height: 80%;

    .mx_CompoundDialog_footer {
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05); /* hardcoded colour for both themes */
        z-index: 1; /* needed to make footer & shadow appear above dialog content */
    }
}
