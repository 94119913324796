/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_LocationViewDialog_wrapper .mx_Dialog {
    padding: 0px;
}

.mx_LocationViewDialog {
    /* subtract 0.5px to prevent single-pixel margin due to rounding */
    width: calc(80vw - 0.5px);
    height: calc(80vh - 0.5px);
    overflow: hidden;

    .mx_Dialog_header {
        margin: 0px;
        padding: 0px;
        position: unset;

        .mx_Dialog_title {
            display: none;
        }
    }

    .mx_Dialog_cancelButton {
        z-index: 4010;
        position: absolute;
        left: var(--cpd-space-4x);
        top: var(--cpd-space-4x);
    }
}

.mx_LocationViewDialog_map {
    width: 80vw;
    height: 80vh;
    border-radius: 8px;
}
