/*
Copyright 2019-2024 New Vector Ltd.
Copyright 2019 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_GeneralUserSettingsTab_section--discovery_existing_verification {
    display: inline-flex;
    align-items: center;

    .mx_Field {
        margin: 0 0 0 1em;
    }
}

.mx_PhoneNumbers_input {
    display: flex;
    align-items: center;
}

.mx_PhoneNumbers_input > .mx_Field {
    flex-grow: 1;
}

.mx_PhoneNumbers_country {
    width: 80px;
}
