/*
Copyright 2018-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_MStickerBody_wrapper {
    padding: 12px 0px;
}

.mx_MStickerBody_hidden {
    max-width: 220px;
    text-decoration: none;
    text-align: center;

    /* To center the text in the middle of the frame */
    display: flex;
    align-items: center;
    justify-content: center;
}

.mx_MStickerBody_placeholder {
    /* centering */
    position: absolute;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
}
