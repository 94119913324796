/*
Copyright 2024 New Vector Ltd.
Copyright 2017 Michael Telatynski <7t3chguy@gmail.com>

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_SyntaxHighlight {
    /* inhibit hljs styling */
    background: none !important;
    color: $light-fg-color !important;
}
