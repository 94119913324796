/*
Copyright 2024 New Vector Ltd.
Copyright 2023 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_SessionLockStolenView {
    h1 {
        font-weight: var(--cpd-font-weight-semibold);
        font-size: $font-32px;
        text-align: center;
    }

    h2 {
        margin: 0;
        font-weight: 500;
        font-size: $font-24px;
        text-align: center;
    }
}
