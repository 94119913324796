/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_MapFallback {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;

    background-color: $system;
}

.mx_MapFallback_bg {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    color: $quinary-content;
    z-index: -1;

    pointer-events: none;
}

.mx_MapFallback_icon {
    width: 65px;
    margin-bottom: $spacing-16;
    color: $quaternary-content;
}
