/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_AnalyticsLearnMoreDialog {
    max-width: 500px;
    .mx_AnalyticsLearnMore_image_holder {
        background-image: url("@res/img/element-shiny.svg");
        background-repeat: no-repeat;
        background-position: center top;
        height: 112px;
        padding: 20px 0px;
    }

    .mx_Dialog_content {
        margin-bottom: 0px;
    }

    .mx_AnalyticsLearnMore_copy {
        border-bottom: 1px solid $menu-border-color;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    a {
        color: $accent;
        text-decoration: none;
    }

    .mx_AnalyticsLearnMore_bullets {
        padding-left: 0px;
    }

    .mx_AnalyticsLearnMore_bullets li {
        list-style-type: none;
        padding: 2px 0 0 32px;
        margin-bottom: 20px;
        vertical-align: middle;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            width: 26px;
            height: 26px;
            left: 0;
            top: 0;
            background-color: #0dbd8b;
            mask-image: url("@vector-im/compound-design-tokens/icons/check-circle.svg");
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: contain;
        }
    }
}
