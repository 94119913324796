/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_RedactedBody {
    white-space: pre-wrap;
    color: $secondary-content;
    vertical-align: middle;

    padding-left: 20px;
    position: relative;

    &::before {
        height: 14px;
        width: 14px;
        background-color: $icon-button-color;
        mask-image: url("@vector-im/compound-design-tokens/icons/delete.svg");
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        content: "";
        position: absolute;
        top: 1px;
        left: 0;
    }
}
