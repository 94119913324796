/*
Copyright 2019-2024 New Vector Ltd.
Copyright 2019 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_MessageActionBar {
    --MessageActionBar-size-button: 28px;
    --MessageActionBar-size-box: 32px; /* 28px + 2px (margin) * 2 */
    --MessageActionBar-item-hover-background: var(--cpd-color-bg-subtle-secondary);
    --MessageActionBar-item-hover-borderRadius: 6px;
    --MessageActionBar-item-hover-zIndex: 1;

    position: absolute;
    visibility: hidden;
    cursor: pointer;
    display: flex;
    height: var(--MessageActionBar-size-box);
    line-height: $font-24px;
    border-radius: 8px;
    background: $background;
    border: var(--cpd-border-width-1) solid var(--cpd-color-border-disabled);
    top: -32px;
    right: 8px;
    user-select: none;
    /* Ensure the action bar appears above other things like the read marker */
    /* and sender avatar (for small screens) */
    z-index: 10;

    /* Adds a previous event safe area so that you can't accidentally hover the */
    /* previous event while trying to mouse into the action bar or from the */
    /* react button to its tooltip. */
    &::before {
        content: "";
        position: absolute;
        /* tooltip safe mousing area + tooltip overhang + */
        /* action bar + action bar offset from event */
        width: calc(10px + 48px + 100% + 8px);
        /* safe area + action bar */
        height: calc(20px + 100%);
        top: -12px;
        left: -58px;
        z-index: -1;
        cursor: initial;

        /* stylelint-disable-next-line max-line-length */
        .mx_GenericEventListSummary[data-layout="bubble"]
            .mx_GenericEventListSummary_toggle
            ~ .mx_GenericEventListSummary_unstyledList
            .mx_EventTile_info:first-of-type
            & {
            /* improve clickability of "collapse" link button on bubble layout by reducing width and height values */
            /* mx_GenericEventListSummary_toggle ~: to apply rules to action bar when "collapse" button is available */
            /* mx_EventTile_info:first-of-type: to apply rules to the info event tile just under "collapse" button */
            /* TODO: use a new class name instead */
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        .mx_EventTile_info .mx_ViewSourceEvent ~ & {
            /* improve clickability of view source event toggle button by removing vertical safe area */
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    > * {
        white-space: nowrap;
        display: inline-block;
        position: relative;
        margin: 2px;

        &:hover {
            background: var(--MessageActionBar-item-hover-background);
            border-radius: var(--MessageActionBar-item-hover-borderRadius);
            z-index: var(--MessageActionBar-item-hover-zIndex);
        }
    }

    .mx_MessageActionBar_iconButton {
        --MessageActionBar-icon-size: 18px;
        width: var(--MessageActionBar-size-button);
        height: var(--MessageActionBar-size-button);
        color: var(--cpd-color-icon-secondary);
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            height: var(--MessageActionBar-icon-size);
            width: var(--MessageActionBar-icon-size);
            flex: 0 0 var(--MessageActionBar-icon-size);
        }

        &:disabled,
        &[disabled] {
            cursor: not-allowed;
            opacity: 0.75;
        }

        &:hover {
            color: var(--cpd-color-icon-primary);
        }

        &.mx_MessageActionBar_threadButton {
            --MessageActionBar-icon-size: 20px;
        }

        &.mx_MessageActionBar_retryButton {
            --MessageActionBar-icon-size: 16px;
        }

        &.mx_MessageActionBar_downloadButton {
            --MessageActionBar-icon-size: 20px;

            &.mx_MessageActionBar_downloadSpinnerButton {
                svg {
                    display: none; /* hide the download icon */
                }
            }
        }

        &.mx_MessageActionBar_expandCollapseMessageButton {
            --MessageActionBar-icon-size: 12px;
        }
    }

    .mx_MessageActionBar_optionsButton {
        --MessageActionBar-icon-size: 22px;
    }
}
