/*
 * Copyright 2024 New Vector Ltd.
 *
 * SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
 * Please see LICENSE files in the repository root for full details.
 */

.mx_SettingsSubheader {
    display: flex;
    flex-direction: column;
    gap: var(--cpd-space-2x);

    > span {
        display: flex;
        align-items: center;
        gap: var(--cpd-space-2x);
        font: var(--cpd-font-body-sm-medium);
    }

    .mx_SettingsSubheader_success {
        color: var(--cpd-color-text-success-primary);
    }

    .mx_SettingsSubheader_error {
        color: var(--cpd-color-text-critical-primary);
    }
}
