/*
Copyright 2024 New Vector Ltd.
Copyright 2021 Robin Townsend <robin@robin.town>

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_ForwardDialog {
    width: 520px;
    color: $primary-content;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: 0;
    height: 80vh;

    > h3 {
        margin: 0 0 6px;
        color: $secondary-content;
        font-size: $font-12px;
        font-weight: var(--cpd-font-weight-semibold);
        line-height: $font-15px;
    }

    > .mx_ForwardDialog_preview {
        max-height: 30%;
        flex-shrink: 0;
        overflow-y: auto;

        .mx_EventTile[data-layout="bubble"] {
            margin-top: 20px;
        }

        div {
            pointer-events: none;
        }

        /* When forwarding messages from encrypted rooms, EventTile will complain */
        /* that our preview is unencrypted, which doesn't actually matter */
        /* We also hide download links to not encourage users to try interacting */
        .mx_EventTile_msgOption,
        .mx_EventTile_e2eIcon_unencrypted,
        .mx_EventTile_e2eIcon_warning,
        .mx_MFileBody_download {
            display: none;
        }
    }

    > hr {
        width: 100%;
        border: none;
        border-top: 1px solid $input-border-color;
        margin: 12px 0;
    }

    > .mx_ForwardList {
        display: contents;

        .mx_SearchBox {
            /* To match the space around the title */
            margin: 0 0 15px 0;
            flex-grow: 0;
        }

        .mx_ForwardList_content {
            flex-grow: 1;
        }

        .mx_ForwardList_noResults {
            display: block;
            margin-top: 24px;
        }

        .mx_ForwardList_results {
            &:not(:first-child) {
                margin-top: 24px;
            }

            .mx_ForwardList_resultsList {
                padding-right: 8px;
            }

            .mx_ForwardList_entry {
                display: flex;
                justify-content: space-between;
                height: 32px;
                padding: 6px;
                border-radius: 8px;

                &:hover,
                &.mx_ForwardList_entry_active {
                    background-color: $spacePanel-bg-color;
                }

                .mx_ForwardList_roomButton {
                    display: flex;
                    margin-right: 12px;
                    min-width: 0;

                    .mx_DecoratedRoomAvatar {
                        margin-right: 12px;
                    }

                    .mx_ForwardList_entry_name,
                    .mx_ForwardList_entry_detail {
                        line-height: 30px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .mx_ForwardList_entry_name {
                        font-size: $font-15px;
                    }

                    .mx_ForwardList_entry_detail {
                        font-size: $font-12px;
                        margin-left: 8px;
                        color: $tertiary-content;
                    }
                }

                .mx_ForwardList_sendButton {
                    position: relative;
                    margin-left: 12px;

                    &:not(.mx_ForwardList_canSend) .mx_ForwardList_sendLabel {
                        /* Hide the "Send" label while preserving button size */
                        visibility: hidden;
                    }

                    .mx_ForwardList_sendIcon,
                    .mx_NotificationBadge {
                        position: absolute;
                    }

                    .mx_NotificationBadge {
                        /* Match the failed to send indicator's color with the disabled button */
                        background-color: $button-danger-disabled-fg-color;
                    }

                    &.mx_ForwardList_sending .mx_ForwardList_sendIcon {
                        background-color: $accent;
                        mask-image: url("@res/img/element-icons/circle-sending.svg");
                        mask-position: center;
                        mask-repeat: no-repeat;
                        mask-size: 14px;
                        width: 14px;
                        height: 14px;
                    }

                    &.mx_ForwardList_sent .mx_ForwardList_sendIcon {
                        background-color: $accent;
                        mask-image: url("@res/img/element-icons/circle-sent.svg");
                        mask-position: center;
                        mask-repeat: no-repeat;
                        mask-size: 14px;
                        width: 14px;
                        height: 14px;
                    }
                }
            }
        }
    }
}
