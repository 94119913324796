/* the 'src' links are relative to the bundle.css, which is in a subdirectory.
 */

/* Twemoji COLR */
@font-face {
    font-family: "Twemoji";
    font-weight: 400;
    src: url("@res/fonts/Twemoji_Mozilla/TwemojiMozilla-colr.woff2") format("woff2");
}
/* For at least Chrome on Windows 10, we have to explictly add extra weights for the emoji to appear in bold messages, etc. */
@font-face {
    font-family: "Twemoji";
    font-weight: 600;
    src: url("@res/fonts/Twemoji_Mozilla/TwemojiMozilla-colr.woff2") format("woff2");
}
@font-face {
    font-family: "Twemoji";
    font-weight: 700;
    src: url("@res/fonts/Twemoji_Mozilla/TwemojiMozilla-colr.woff2") format("woff2");
}
