/*
Copyright 2018-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

/* make any scrollbar grey and thin */
html {
    scrollbar-color: $scrollbar-thumb-color transparent;
}
/* scrollbar-width is not inherited (but -color is, why?!), */
/* so declare it on every element */
* {
    scrollbar-width: thin;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: $scrollbar-thumb-color;
}

/* make auto-hide scrollbars not transparent again on hover */
.mx_AutoHideScrollbar:hover {
    scrollbar-color: $scrollbar-thumb-color transparent;

    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb-color;
    }
}

/* make scrollbars transparent for autohide scrollbars */
.mx_AutoHideScrollbar {
    overflow-x: hidden;
    overflow-y: auto;
    overflow-y: overlay; /* where supported */
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    scrollbar-color: transparent transparent;
}
