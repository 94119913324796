/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_ZoomButtons {
    position: absolute;
    bottom: $spacing-32;
    right: $spacing-24;
    display: flex;
    flex-direction: column;
    row-gap: $spacing-8;

    .mx_ZoomButtons_button {
        $ZoomButtons_button-size: 24px;

        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: $ZoomButtons_button-size;
        width: $ZoomButtons_button-size;
        background: $background;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

        .mx_ZoomButtons_icon {
            $ZoomButtons_icon-size: 12px;

            height: $ZoomButtons_icon-size;
            width: $ZoomButtons_icon-size;
            color: $primary-content;
        }
    }
}
