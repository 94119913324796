/*
Copyright 2024 New Vector Ltd.
Copyright 2015, 2016 OpenMarket Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_TextualEvent {
    overflow-y: hidden;
    line-height: normal;

    a {
        color: $accent;
        cursor: pointer;
    }

    .mx_RoomView_searchResultsPanel & {
        opacity: unset; /* Unset the opacity value specified above on the search results panel */
    }
}
